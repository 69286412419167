import { useState, useEffect, forwardRef } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Select, Tag } from 'antd';
import classnames from 'classnames';
import Highlighter from 'react-highlight-words';
import stringSimilarity from 'string-similarity';

import { getAllAreas } from 'services/cities';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import BRButton from 'components/BRButton/BRButton';

import { ReactComponent as Down } from 'assets/icons/DownArrowIcon.svg';
import EmptySearchView from 'assets/icons/Empty-Search-View.svg';

import './BRCityArea.less';

export const AVAILABILITY = {
  DROPOFF: 'dropOffAvailability',
  PICKUP: 'pickupAvailability'
};

export const CONTEXT = {
  dropOffAvailability: 'dropoff',
  pickupAvailability: 'pickup'
};

const BRCityArea = (props, ref) => {
  const {
    formRef,
    intl,
    name,
    areas,
    availability,
    formItemClassName = '',
    onChange,
    disabled,
    dropdownWidth,
    hasFeedback,
    cityID,
    initialValue,
    setCities,
    initialCityValue,
    initialAreaValue,
    initialDistrictValue,
    updateAddress,
    ...restOfProps
  } = props;

  const [searchValue, setSearchValue] = useState('');
  const [isAllAreasLoading, setIsAreasLoading] = useState(false);
  const [allAreas, setAllAreas] = useState([]);
  const [expandedCity, setExpandedCity] = useState([]);
  const [allCitiesIds, setAllCitiesIds] = useState([]);
  const [areaDropdownVisible, setAreaDropdownVisible] = useState(false);

  useEffect(() => {
    if (cityID) {
      setExpandedCity([...expandedCity, cityID]);
    }
  }, [cityID]);

  useEffect(() => {
    async function fetchData() {
      setIsAreasLoading(true);
      const { data } = await getAllAreas({
        context: CONTEXT[availability]
      });
      setAllCitiesIds(data.map(({ cityId }) => cityId));
      setAllAreas(data);
      setCities?.(data);
      setIsAreasLoading(false);
    }
    fetchData();
  }, [availability]);

  const findCity = () => {
    const foundCity = allAreas.find(
      (city) =>
        initialCityValue
          ?.toLowerCase()
          ?.includes(city?.cityName?.toLowerCase()) ||
        initialCityValue
          ?.toLowerCase()
          ?.includes(city?.cityOtherName?.toLowerCase())
    );

    if (!foundCity) {
      const citiesNames = allAreas
        .map((city) => [city?.cityName, city?.cityOtherName])
        .flat();

      const foundMatch =
        !!citiesNames?.length &&
        stringSimilarity.findBestMatch(initialCityValue, citiesNames);

      if (foundMatch?.bestMatch?.rating > 0.6) {
        return allAreas.find((city) =>
          [city?.cityName, city?.cityOtherName].includes(
            foundMatch?.bestMatch?.target
          )
        );
      }
    }

    return foundCity;
  };

  const findArea = ({ foundCity } = {}) => {
    const districtOrAreaValue = initialDistrictValue || initialAreaValue;

    const foundArea = foundCity?.districts?.find(
      (area) =>
        districtOrAreaValue
          ?.toLowerCase()
          ?.includes(area?.districtName?.toLowerCase()) ||
        districtOrAreaValue
          ?.toLowerCase()
          ?.includes(area?.districtOtherName?.toLowerCase())
    );

    if (!foundArea) {
      const districtNames = foundCity?.districts
        ?.map((district) => [
          district?.districtName,
          district?.districtOtherName
        ])
        ?.flat();

      const foundMatch =
        !!districtNames?.length &&
        stringSimilarity.findBestMatch(districtOrAreaValue, districtNames);

      if (foundMatch?.bestMatch?.rating > 0.6) {
        return foundCity?.districts?.find((area) =>
          [area?.districtName, area?.districtOtherName].includes(
            foundMatch?.bestMatch?.target
          )
        );
      }
    }

    return foundArea;
  };

  useEffect(() => {
    if (initialCityValue && (initialDistrictValue || initialAreaValue)) {
      const foundCity = findCity();
      const foundArea = findArea({ foundCity });

      onChange?.({
        cityName: foundCity?.cityName,
        cityID: foundCity?.cityId,
        areaName: foundArea?.districtName,
        areaId: foundArea?.districtId
      });

      setExpandedCity([...expandedCity, foundCity?.cityId]);

      formRef?.current?.setFieldsValue({
        address: { districtId: foundArea?.districtId }
      });

      updateAddress?.({
        city: foundCity?.cityName,
        districtId: foundArea?.districtId,
        districtName: foundArea?.districtName
      });
    }
  }, [initialCityValue, allAreas]);

  const checkArea = (area, cityId, cityName) => {
    if (
      (area?.[availability] && area?.districtName === 'Others') ||
      area?.districtName !== 'Others'
    ) {
      return areaOption(area, cityId, cityName);
    }
  };

  const handleExpandCity = (cityId) => {
    if (expandedCity.includes(cityId)) {
      setExpandedCity(expandedCity.filter((city) => city !== cityId));
    } else {
      setExpandedCity([cityId]);
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
    formRef?.current?.resetFields([name]);
    setAreaDropdownVisible(true);
  };

  const areaOption = (area, cityId, cityName) => {
    const { zoneName, districtId, districtName } = area;

    return (
      <Select.Option
        key={districtId}
        value={districtId}
        label={
          zoneName === districtName ? zoneName : `${zoneName} - ${districtName}`
        }
        data-city-id={cityId}
        disabled={area?.[availability] === false}
      >
        <div
          className={classnames('br-city-area__dropdown', {
            'br-uncovered-area': area?.[availability] === false
          })}
        >
          <div className="display-flex align-center br-city-area__content">
            <span className="br-city-area__selected-indicator"></span>
            <Highlighter
              highlightClassName="br-city-area__highlighted-text"
              searchWords={[searchValue]}
              autoEscape={true}
              textToHighlight={
                zoneName === districtName
                  ? zoneName
                  : `${zoneName} - ${districtName}`
              }
            />
          </div>
          <span className="br-city-area__city-name">{cityName}</span>
          {area?.[availability] === false && (
            <Tag className="br-city-area__uncovered-tag ant-tag-light-gray">
              {intl.formatMessage({
                id: 'address_details.uncovered_zone'
              })}
            </Tag>
          )}
        </div>
      </Select.Option>
    );
  };

  return (
    <Form.Item
      name={name || 'districtId'}
      rules={[{ required: true }]}
      className={`${formItemClassName} br-forms-new-input br-form-city-area`}
      label={intl.formatMessage({
        id: 'address_details.area'
      })}
      initialValue={initialValue}
      hasFeedback
    >
      <Select
        className="br-city-area"
        placeholder={intl.formatMessage({
          id: 'address_details.area_select_placeholder'
        })}
        getPopupContainer={(triggerNode) => triggerNode.parentElement}
        dropdownAlign={{
          overflow: {
            adjustY: false, // Prevent vertical adjustment
          },
        }}
        loading={isAllAreasLoading}
        disabled={disabled}
        showSearch
        searchValue={searchValue}
        optionFilterProp="label"
        onDropdownVisibleChange={(open) => {
          setAreaDropdownVisible(open);
        }}
        onChange={(value, option) => {
          const city = allAreas.find(
            (city) => city.cityId === option['data-city-id']
          );
          setExpandedCity([city.cityId]);
          if (onChange) {
            onChange({
              cityName: city?.cityName,
              cityID: option['data-city-id'],
              areaName: option?.label,
              areaId: option?.value
            });
          }
          setSearchValue('');
        }}
        onClear={() => {
          setSearchValue('');
        }}
        onSearch={(value) => {
          setExpandedCity(value ? allCitiesIds : []);
          setSearchValue(value);
        }}
        open={areaDropdownVisible}
        filterOption={(input, option) => {
          if (option.options) {
            return option.label.props.children[0].props?.textToHighlight
              .toLowerCase()
              .includes(input.toLowerCase());
          } else {
            return option.label.toLowerCase().includes(input.toLowerCase());
          }
        }}
        dropdownMatchSelectWidth={dropdownWidth}
        dropdownRender={(menu) =>
          isAllAreasLoading ? <LoadingWrapper /> : menu
        }
        notFoundContent={
          <div className="br-city-area__no-results-search">
            <img alt="" src={EmptySearchView} />
            <span className="heading">
              {intl.formatMessage({
                id: 'address_details.no_search_title'
              })}
            </span>
            <BRButton
              type="treitary-color"
              className="button-sm"
              label={intl.formatMessage({
                id: 'address_details.clear_search'
              })}
              onClick={handleClearSearch}
            />
          </div>
        }
        {...restOfProps}
      >
        {allAreas.map((city) => {
          return (
            <Select.OptGroup
              key={city.cityId}
              value={city.cityId}
              label={
                <span
                  className={classnames('br-city-area-city-group-label', {
                    'br-city-area-city-group__expanded': expandedCity.includes(
                      city.cityId
                    )
                  })}
                  onClick={() => handleExpandCity(city.cityId)}
                >
                  <Highlighter
                    highlightClassName="br-city-area__highlighted-text"
                    searchWords={[searchValue]}
                    autoEscape={true}
                    textToHighlight={city.cityName}
                  />
                  <Down />
                </span>
              }
            >
              {expandedCity.includes(city.cityId) &&
                city.districts.map((area) =>
                  checkArea(area, city.cityId, city.cityName)
                )}
            </Select.OptGroup>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default injectIntl(forwardRef(BRCityArea), { forwardRef: true });
