export const LOCAL_STORAGE_LOCALE_KEY = 'bosta.co-Locale';

export const LOCALE = {
  EN: 'en',
  EN_SA: 'en-sa',
  EN_EG: 'en-eg',
  AR: 'ar-EG',
  AR_SA: 'ar-SA'
};

export const COUNTRIES_ENGLISH_LOCALE = ['en-eg', 'en-sa'];

export const COUNTRIES_ARABIC_LOCALE = [LOCALE.AR, LOCALE.AR_SA];
