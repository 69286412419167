import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import AuthReducer from 'reducer/AuthReducer';

const INITIAL_STATE = {
  currentUser: JSON.parse(localStorage.getItem('user')) || null
};

export const AuthContext = createContext(INITIAL_STATE);

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE);
  const [apiKey, setAPIKey] = useState();

  useEffect(() => {
    localStorage.setItem('user', JSON.stringify(state.currentUser));
  }, [state.currentUser]);

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, dispatch, apiKey, setAPIKey }}
    >
      {children}
    </AuthContext.Provider>
  );
};
