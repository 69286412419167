import { useState, useRef, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { Form, Input } from 'antd';
import classnames from 'classnames';

import { sendSegment } from 'utils/segment';
import {
  secondsToTime,
  numberRegex,
  countDown,
  TWO_MINUTES
} from 'utils/helpers';
import { REGEX_NUMERIC_PATTERN } from 'constants/shipments';

import LoadingWrapper from 'components/LoadingWrapper/LoadingWrapper';
import { notify } from 'components/Notify/Notify';
import BRButton from 'components/BRButton/BRButton';
import BRContentHeader from 'components/BRContentHeader/BRContentHeader';

import './BROTPCode.less';

const BROTPCode = ({
  title,
  closeModal,
  intl,
  confirmOTP,
  generateOTP,
  onSuccess,
  resendCodeSegmentEvent,
  verificationGenerateOTPCode,
  handleResendCode,
  phoneNumber,
  isInternalComponent,
  wrongOTP,
  setWrongOTP,
  headerClassName,
  setConfirmDisabled,
  setConfirmLoading
}) => {
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [timer, setTimer] = useState(TWO_MINUTES);

  const [otp, setOtp] = useState('');
  const otpDigits = otp.split('');
  const otpRefs = useRef([]);

  const sendOtp = useCallback(async () => {
    try {
      setPageLoading(true);
      if (generateOTP) {
        await generateOTP();
      } else if (verificationGenerateOTPCode) {
        verificationGenerateOTPCode();
      }
    } catch (error) {
      notify({ msg: error.message, error });
    }
    otpRefs.current[0]?.focus();
    setPageLoading(false);
  }, [generateOTP, verificationGenerateOTPCode]);

  useEffect(() => {
    if (generateOTP) {
      sendOtp();
    } else {
      otpRefs.current[0]?.focus();
    }
  }, [generateOTP, sendOtp]);

  useEffect(() => {
    const myInterval = countDown({ timer, setTimer, setDisabled });
    return () => {
      clearInterval(myInterval);
    };
  }, [timer]);

  useEffect(() => {
    setTimer(TWO_MINUTES);
    setDisabled(true);
  }, [phoneNumber]);

  useEffect(() => {
    if (!('OTPCredential' in window)) {
      // Return if the WebOTP API is not supported
      return;
    }

    const abortController = new AbortController();
    const abortTimeout = setTimeout(() => {
      abortController.abort(); // Cancel waiting for the OTP after 2 minutes
    }, 2 * 60 * 1000);

    navigator.credentials
      .get({
        otp: { transport: ['sms'] },
        signal: abortController.signal
      })
      .then((otp) => {
        if (otp.code?.length < 4) {
          return;
        }
        setOtp(otp.code);
        otpRefs.current[0]?.focus();
        handleOnFinish(otp.code);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => clearTimeout(abortTimeout);
  }, [isErrorVisible]);

  const handleOnFinish = async (otpValue) => {
    try {
      setPageLoading(true);
      setConfirmDisabled && setConfirmDisabled(false);
      setConfirmLoading && setConfirmLoading(true);
      await confirmOTP(otpValue);
      setIsErrorVisible(false);
      onSuccess && onSuccess();
      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      setIsErrorVisible(true);
      setOtp('');
      otpRefs.current[0]?.focus();
    }
    setPageLoading(false);
  };

  const handleSetOTP = (digitIndex) => {
    return (e) => {
      const otpDigitsCount = otpRefs.current.length;
      let userInput = e.nativeEvent.data ?? e.target.value;

      if (userInput.length === 0) {
        // if the user erases the current character
        setOtp((currentOtp) => currentOtp.slice(0, digitIndex)); // delete all moving forwards
        otpRefs.current[digitIndex - 1]?.focus();
        return;
      }

      if (!numberRegex.test(userInput)) {
        // check if the character is a number
        return;
      }

      if (userInput.length >= otpDigitsCount) {
        const otpDigits = userInput.slice(0, otpDigitsCount);
        setOtp(otpDigits);
        handleOnFinish(otpDigits);
        return;
      }

      // if the user enters more than 1 character, take as much as needed only
      userInput = userInput.slice(0, otpDigitsCount - digitIndex);

      const firstHalf = otp.slice(0, digitIndex);
      const secondHalf = otp.slice(digitIndex + 1);
      const rebuiltOTP = `${firstHalf}${userInput}${secondHalf}`;
      const updatedOTP = rebuiltOTP.slice(0, otpDigitsCount);

      otpRefs.current[updatedOTP.length]?.focus();
      setOtp(updatedOTP);

      if (updatedOTP.length === otpDigitsCount) {
        handleOnFinish(updatedOTP);
      }
    };
  };

  const handleWrongOTP = () => {
    setWrongOTP?.(false);
  };

  const handleSendAgain = () => {
    setDisabled(true);
    setIsErrorVisible(false);
    setTimer(TWO_MINUTES);
    handleResendCode ? handleResendCode() : sendOtp();

    if (resendCodeSegmentEvent && !handleResendCode) {
      sendSegment(resendCodeSegmentEvent);
    }
  };

  return (
    <LoadingWrapper loading={pageLoading}>
      {title && (
        <BRContentHeader
          title={title}
          className={headerClassName}
          isInternalComponent={isInternalComponent}
        />
      )}
      <div className="br-otp-validation__content">
        <Form className="br-otp-modal__otp-code">
          <div className="br-otp-modal__header">
            <span className="br-otp-modal__code-sent body">
              {intl.formatMessage(
                {
                  id: 'verify_phone.code_sent_label'
                },
                {
                  phoneNumber: (
                    <span className="br-otp-modal__code-sent__phone body-medium">
                      {phoneNumber}
                    </span>
                  )
                }
              )}
            </span>
            {disabled && (
              <span className="br-otp-validation__receive-confirmation body">
                {intl.formatMessage(
                  {
                    id: 'verify_phone.otp_screen.receive_confirmation'
                  },
                  {
                    time: (
                      <span className="br-otp-validation__receive-confirmation__timer body-medium">
                        {secondsToTime(timer)}
                      </span>
                    )
                  }
                )}
              </span>
            )}
            <BRButton
              type={disabled ? 'link-gray' : 'link-color'}
              disabled={disabled}
              onClick={handleSendAgain}
              label={intl.formatMessage({
                id: 'verify_phone.otp_screen.resend_code'
              })}
            />
          </div>

          <div
            className={classnames('br-otp-modal__form-items', {
              'error-visible': isErrorVisible
            })}
          >
            {Array.from({ length: 4 }).map((otpDigit, i) => (
              <Form.Item
                key={`otp-digit-${i}`}
                validateStatus={wrongOTP ? 'error' : undefined}
                onChange={handleWrongOTP}
              >
                <Input
                  type="text"
                  data-hj-allow
                  value={otpDigits[i]}
                  onChange={handleSetOTP(i)}
                  placeholder="_"
                  ref={(el) => (otpRefs.current[i] = el)}
                  pattern={REGEX_NUMERIC_PATTERN}
                />
              </Form.Item>
            ))}
          </div>

          {wrongOTP && (
            <p className="br-opt-modal__error-text body">
              {intl.formatMessage({
                id: 'verify_phone.otp_screen.error'
              })}
            </p>
          )}
        </Form>
      </div>
    </LoadingWrapper>
  );
};

export default injectIntl(BROTPCode);
